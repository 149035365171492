import { Box, Tab, Tabs } from '@material-ui/core';
import { FilterBarWrapper, FilterSetsProvider } from '../FilterBarWrapper';
import { FiltersProvider, useFilters } from './FiltersContext';
import React, { useEffect, useState } from 'react';

import { CompanyAnalysis } from './components/CompanyAnalysis';
import { Filters } from './components/Filters';
import { Notes } from './components/Notes';
import { Overview } from './components/Overview';
import { PageActions } from '../PageActions';
import { TransactionAnalytics } from './components/TransactionAnalytics';
import { TransactionTable } from './components/TransactionTable';
import { useStyles } from './styles';

const TABS = [
  {
    label: 'Overview',
    component: Overview,
    tag: 'overview',
  },
  {
    label: 'Transactions Table',
    component: TransactionTable,
    tag: 'transactions',
  },
  {
    label: 'Transaction Analytics',
    component: TransactionAnalytics,
    tag: 'analytics',
  },
  {
    label: 'Company Analysis',
    component: CompanyAnalysis,
    tag: 'analysis',
  },
];

const MaPageInner = () => {
  const classes = useStyles();
  // start with no tab selected to avoid rendering the first tab by default
  const [currentTab, setCurrentTab] = useState(-1);
  const { resetFilters } = useFilters();

  const onTabChange = (newValue) => {
    setCurrentTab(newValue);
    // update URL hash
    window.location.hash = TABS[newValue].tag;
  };

  useEffect(() => {
    // restore tab from URL hash
    const tag = window.location.hash.replace('#', '');
    const tab = TABS.findIndex((tab) => tab.tag === tag);
    // findIndex returns -1 if not found, so we set the current tab to 0 in that case
    onTabChange(tab === -1 ? 0 : tab);
  }, []);

  // if no tab is selected, show nothing
  if (currentTab === -1) return null;

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <FilterSetsProvider filterSetsCount={1}>
          <FilterBarWrapper filters={<Filters />}>
            <PageActions onClearFilters={resetFilters} />
          </FilterBarWrapper>
        </FilterSetsProvider>
        <Box className={classes.contentWrapper}>
          <Tabs className={classes.tabs} value={currentTab} onChange={(_, tab) => onTabChange(tab)}>
            {TABS.map((tab, index) => (
              <Tab className={classes.tab} key={index} label={tab.label} />
            ))}
          </Tabs>

          {TABS.map((tab, index) => currentTab === index && <tab.component key={index} />)}
        </Box>
      </Box>
      <Notes />
    </Box>
  );
};

export const MaPage = () => (
  <FiltersProvider>
    <MaPageInner />
  </FiltersProvider>
);
