// React component for ShinyDownloads page
import React, { useState } from 'react';

import DataDownloadsContainer from './DataDownloadsContainer';
import Filters from './Filters';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';

const filtersSessionStorageKey = 'shinyDownloadsFilters';

const defaultFilters = {
  downloadType: null,
  regions: [],
  countries: [],
};

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.sizes.fullPage,
    flexWrap: 'nowrap',
  },
  shinyDownloadsMain: {
    display: 'flex',
    flexDirection: 'row',
    flexBasis: '100%',
    borderTop: `solid ${theme.palette.baseColors.iceBlue.c100} thin`,
  },
}));

const getDefaultFilters = () => {
  const filters = JSON.parse(sessionStorage.getItem(filtersSessionStorageKey));
  if (
    filters &&
    Object.keys(defaultFilters).every((filter) => Object.keys(filters).includes(filter))
  ) {
    return filters;
  }
  return defaultFilters;
};

const ShinyDownloads = () => {
  const classes = useStyles();

  const [filters, setFilterState] = useState(getDefaultFilters());

  const setFilters = (newFilters) => {
    sessionStorage.setItem(filtersSessionStorageKey, JSON.stringify(newFilters));
    setFilterState(newFilters);
  };

  const clearFilters = () => {
    setFilters(defaultFilters);
  };

  return (
    <Grid className={classes.root} container direction="column">
      <Filters filters={filters} setFilters={setFilters} clearFilters={clearFilters} />
      <DataDownloadsContainer filters={filters} />
    </Grid>
  );
};

export default ShinyDownloads;
