import { gql } from '@apollo/client';

export const ASSETS_BY_NAME_AND_COUNTRY_QUERY = gql`
  query assetsByNameAndCountry($search: String!, $country: ID) {
    assetsByNameAndCountry(search: $search, country: $country) {
      id
      legacyId
      name
      displayName
      geom
      hydrocarbonType
      country {
        id
        displayName
        isoCode
        name
      }
    }
  }
`;

export const MAP_SCREENING_QUERY = gql`
  query mapScreening(
    $country: [ID!]
    $company: [ID!]
    $basin: [ID!]
    $shoreStatus: [String!]
    $hydrocarbonType: [String!]
    $prodMin: Float
    $prodMax: Float
    $npvMin: Float
    $npvMax: Float
    $operator: Boolean
    $reservesMin: Float
    $reservesMax: Float
    $opportunities: Boolean
  ) {
    mapScreening(
      country: $country
      company: $company
      basin: $basin
      shoreStatus: $shoreStatus
      hydrocarbonType: $hydrocarbonType
      prodMin: $prodMin
      prodMax: $prodMax
      npvMin: $npvMin
      npvMax: $npvMax
      operator: $operator
      reservesMin: $reservesMin
      reservesMax: $reservesMax
      opportunities: $opportunities
    ) {
      id
      legacyId
      displayName
      entityType
      name
      geom
      country {
        id
        isoCode
        name
      }
      sectionGeomCoordinates
    }
  }
`;

export const MAP_SCREENING_EA_WELLS_QUERY = gql`
  query mapScreeningEaWells(
    $countryIsoCodes: [String!]
    $companyNames: [String!]
    $basinNames: [String!]
    $shoreStatusNames: [String!]
    $hydrocarbonType: [String!]
    $prodMin: Float
    $prodMax: Float
    $npvMin: Float
    $npvMax: Float
    $operator: Boolean
    $reservesMin: Float
    $reservesMax: Float
    $eaWells: Boolean
  ) {
    mapScreeningEaWells(
      countryIsoCodes: $countryIsoCodes
      companyNames: $companyNames
      basinNames: $basinNames
      shoreStatusNames: $shoreStatusNames
      hydrocarbonType: $hydrocarbonType
      prodMin: $prodMin
      prodMax: $prodMax
      npvMin: $npvMin
      npvMax: $npvMax
      operator: $operator
      reservesMin: $reservesMin
      reservesMax: $reservesMax
      showEaWells: $eaWells
    ) {
      id
      name
      geom
      entityType
      country {
        id
        isoCode
        name
      }
    }
  }
`;

export const BASINS_WITH_MAP_FILTER_QUERY = gql`
  query basinsWithMapFilter($countries: [String!], $companies: [String!], $operator: Boolean) {
    basinsWithMapFilter(countries: $countries, companies: $companies, operator: $operator) {
      id
      name
    }
  }
`;

export const BLOCKS_BY_NAME_AND_COUNTRY_QUERY = gql`
  query blocksByNameAndCountry($search: String!, $country: ID) {
    blocksByNameAndCountry(search: $search, country: $country) {
      id
      leaseLegacyId
      legacyId
      displayName
      entityType
      name
      geom
      country {
        id
        displayName
        isoCode
      }
    }
  }
`;

export const BLOCKS_AND_LEASES_BY_NAME_AND_COUNTRY_QUERY = gql`
  query blocksAndLeasesByNameAndCountry($search: String!, $country: ID) {
    blocksAndLeasesByNameAndCountry(search: $search, country: $country) {
      id
      leaseLegacyId
      legacyId
      displayName
      entityType
      name
      geom
      country {
        id
        displayName
        isoCode
      }
    }
  }
`;

export const COMPANIES_WITH_MAP_FILTER_QUERY = gql`
  query companiesWithMapFilter($countries: [String!], $basins: [String!], $operator: Boolean) {
    companiesWithMapFilter(countries: $countries, basins: $basins, operator: $operator) {
      id
      name
    }
  }
`;

export const FACILITIES_BY_NAME_AND_COUNTRY_QUERY = gql`
  query facilitiesByNameAndCountry($search: String!, $country: ID) {
    facilitiesByNameAndCountry(search: $search, country: $country) {
      id
      legacyId
      name
      geom
      country {
        id
        displayName
        isoCode
      }
      facilityIcon {
        name
      }
    }
  }
`;

export const FIELDS_BY_NAME_AND_COUNTRY_QUERY = gql`
  query fieldsByNameAndCountry($search: String!, $country: ID) {
    fieldsByNameAndCountry(search: $search, country: $country) {
      id
      legacyId
      displayName
      name
      geom
      hydrocarbonType
      country {
        id
        displayName
        isoCode
      }
    }
  }
`;

export const LITHOLOGIES_QUERY = gql`
  {
    lithologies {
      id
      name
      displayName
    }
  }
`;

export const LNG_PROJECTS_BY_NAME_AND_COUNTRY_QUERY = gql`
  query lngProjectsByNameAndCountry($search: String!, $country: String) {
    lngProjectsByNameAndCountry(search: $search, country: $country) {
      id
      category
      name
      geom
      lngType
      iconName
      country {
        id
        displayName
        isoCode
      }
    }
  }
`;

export const PIPELINE_NETWORKS_BY_NAME_AND_COUNTRY_QUERY = gql`
  query pipelineNetworksByNameAndCountry($search: String!, $country: ID) {
    pipelineNetworksByNameAndCountry(search: $search, country: $country) {
      id
      name
      geom
      countries {
        id
        isoCode
      }
    }
  }
`;

export const SHORE_STATUS_QUERY = gql`
  {
    shoreStatus {
      id
      name
    }
  }
`;

export const WELLS_BY_NAME_AND_COUNTRY_QUERY = gql`
  query wellsByNameAndCountry($search: String!, $country: ID) {
    wellsByNameAndCountry(search: $search, country: $country) {
      id
      legacyId
      displayName
      name
      geom
      weaClassification
      asset {
        id
      }
      country {
        id
        displayName
        isoCode
      }
    }
  }
`;
