import React, { useEffect, useMemo, useState } from 'react';
import {
  TRANSACTIONS_AGGREGATE_BY_TYPE_ENUM,
  TRANSACTIONS_AGGREGATE_GRAPH_BY_OPTIONS,
} from '../../types';

import { MaGraph } from '../../../MaGraph';
import { getGraphLayout } from '../../../../helpers';
import { titleize } from '../../../../../../utils/helpers';
import { useGetMaDealCountByType } from '../../hooks/useGetMaDealCountByType';

const MAX_LEGEND_ITEMS = 5;
const SESSION_STORAGE_KEY = 'MaOverviewDealCountByTypeChart';

const getInitialChartSettings = () => {
  const sessionSettings = sessionStorage.getItem(SESSION_STORAGE_KEY);
  if (sessionSettings) {
    return JSON.parse(sessionSettings);
  }

  return {
    aggregateBy: TRANSACTIONS_AGGREGATE_BY_TYPE_ENUM.COUNTRY,
  };
};

export const DealCountByTypeChart = () => {
  const { aggregateBy: defaultAggregateBy } = getInitialChartSettings();
  const [aggregateBy, setAggregateBy] = useState(defaultAggregateBy);

  const { data, dealTypes, loading, error } = useGetMaDealCountByType(aggregateBy);

  useEffect(() => {
    // Save the current state to sessionStorage
    sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify({ aggregateBy }));
  }, [aggregateBy]);

  const chartX = [];
  const chartYs = new Map(dealTypes.map((dealType) => [dealType, []]));
  data.forEach((d) => {
    // get list of X-axis values (e.g. countries, years, etc.)
    chartX.push(d.group);
    // get list of Y-axis values for each deal type
    // if a deal type is not present in the data, we need to default to 0 to keep the chart consistent
    dealTypes.forEach((dealType) => chartYs.get(dealType).push(d.values[dealType] || 0));
  });

  const hoverTitle = titleize(aggregateBy);
  const chartData = useMemo(
    () =>
      [...chartYs].map(([dealType, chartY], idx) => ({
        // same X-axis values for all deal types
        x: chartX,
        // one Y-axis value for each deal type
        y: chartY,
        name: dealType,
        type: 'bar',
        hovertemplate: `Asset Event: ${dealType} <br>${hoverTitle}: %{x}<br>Count: %{y:,}<extra></extra>`,
        showlegend: idx < MAX_LEGEND_ITEMS,
      })),
    [chartYs],
  );

  if (error) return null;

  return (
    <MaGraph
      data={chartData}
      title={`Number of Events by ${titleize(aggregateBy)}`}
      loading={loading}
      xConfig={{
        value: aggregateBy,
        setValue: setAggregateBy,
        options: TRANSACTIONS_AGGREGATE_GRAPH_BY_OPTIONS,
      }}
      graphLayout={getGraphLayout({ showLegend: true })}
    />
  );
};
