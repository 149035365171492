import React, { useEffect } from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { NO_VALUE } from '../Graphs/constants';
import Radio from '@material-ui/core/Radio';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  label: {
    margin: 0,
  },
  control: {
    color: 'white',
    '&.Mui-checked': {
      ...theme.palette.accentText,
    },
  },
  radioRow: {
    width: '100%',
    justifyContent: 'space-between',
    margin: 0,
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.26)',
  },
}));

// item - the radio button item ex: { isDisabled: (itemIsDisabledParams) => {}, label: 'Satellite', value: 'satellite' }
// styles - styles to override
export default ({
  handleToggle,
  value,
  item = {},
  itemIsDisabledParams = {},
  labelPlacement = 'end',
  styles = {},
}) => {
  const classes = useStyles();

  useEffect(() => {
    // if item is disabled, and the value is selected, then deselect it
    if (item.isDisabled && item.isDisabled(itemIsDisabledParams) && value === item.value) {
      handleToggle({ target: { value: NO_VALUE } });
    }
  }, [item.isDisabled && item.isDisabled(itemIsDisabledParams)]);

  return (
    <Tooltip title={item.tooltip || ''}>
      {item.options ? (
        <Grid
          className={clsx({
            [classes.radioRow]: true,
            [classes.disabled]: item.isDisabled && item.isDisabled(itemIsDisabledParams),
          })}
          container
          item
          direction="row"
          alignItems="center"
        >
          <Typography style={{ fontWeight: 'bold' }}>{item.label}</Typography>
          <Grid item>
            {item.options.map((option) => (
              <FormControlLabel
                key={`radio-item-${item.label}-option-${option.label}`}
                style={{ margin: 0 }}
                value={option.value}
                control={
                  <Radio
                    onClick={handleToggle}
                    disabled={
                      (item.isDisabled && item.isDisabled(itemIsDisabledParams)) ||
                      (option.isDisabled && option.isDisabled(itemIsDisabledParams))
                    }
                    className={clsx({
                      [classes.control]: true,
                      [styles.radioItemControl]: !!styles.radioItemControl,
                    })}
                  />
                }
                label={option.label}
                labelPlacement={labelPlacement}
              />
            ))}
          </Grid>
        </Grid>
      ) : (
        <FormControlLabel
          className={clsx({
            [classes.label]: true,
            [styles.radioItemLabel]: !!styles.radioItemLabel,
          })}
          value={item.value}
          control={
            <Radio
              onClick={handleToggle}
              disabled={(item.isDisabled && item.isDisabled(itemIsDisabledParams)) || false}
              className={clsx({
                [classes.control]: true,
                [styles.radioItemControl]: !!styles.radioItemControl,
              })}
            />
          }
          label={item.label}
          labelPlacement={labelPlacement}
        />
      )}
    </Tooltip>
  );
};
