import { ApolloProvider } from '@apollo/client';
import { AppRoutes } from '../utils/routes';
import { CurrentUserProvider } from '../components/CurrentUserContext';
import { FeatureFlagsProvider } from '../contexts/FeatureFlagsContext';
import Footer from '../components/Footer';
import { MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from '../components/SnackbarContext';
import { WalkthroughProvider } from '../components/WalkthroughContext';
import { createClient } from '../utils/apollo';
import { render } from 'react-dom';
import theme from '../utils/theme';

const App = () => {
  return (
    <ApolloProvider client={createClient()}>
      <MuiThemeProvider theme={theme}>
        <Router>
          <SnackbarProvider>
            <CurrentUserProvider>
              <FeatureFlagsProvider>
                <WalkthroughProvider>
                  <AppRoutes />
                </WalkthroughProvider>
              </FeatureFlagsProvider>
            </CurrentUserProvider>
          </SnackbarProvider>
          <Footer />
        </Router>
      </MuiThemeProvider>
    </ApolloProvider>
  );
};

render(<App />, document.querySelector('#root'));
