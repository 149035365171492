import Grid from '@material-ui/core/Grid';
import RadioGroup from '@material-ui/core/RadioGroup';
import RadioItem from './radioItem';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    padding: '10px',
    width: '100%',
  },
  radioGroup: {
    flexDirection: 'row',
    width: '100%',
    boxSizing: 'border-box',
  },
}));

// disabled - flag for disabling the group
// handleToggle - the callback to handle the toggling of radio buttons
// items - array of radio item objects
// itemIsDisabledParams - params to pass to the isDisabled function of the radio item
// label - label for the group
// styles - styles to override
// value - the value of the radio group
export default ({
  disabled,
  handleToggle = () => {},
  items = [],
  itemIsDisabledParams = {},
  label,
  labelPlacement,
  styles = {},
  value,
}) => {
  const classes = useStyles();

  return (
    <Grid
      className={clsx({
        [classes.root]: true,
        [styles.radioGroupRoot]: !!styles.radioGroupRoot,
      })}
    >
      {label && (
        <Typography className={clsx({ [styles.label]: !!styles.label })} variant="overline">
          {label}
        </Typography>
      )}
      <RadioGroup
        className={clsx({
          [classes.radioGroup]: true,
          [styles.radioGroup]: !!styles.radioGroup,
        })}
        value={value}
        disabled={disabled}
      >
        {items.map((i) => (
          <RadioItem
            key={`radio-group-item-${i.label}`}
            value={value}
            item={i}
            itemIsDisabledParams={itemIsDisabledParams}
            labelPlacement={labelPlacement}
            styles={styles}
            handleToggle={handleToggle}
          />
        ))}
      </RadioGroup>
    </Grid>
  );
};
