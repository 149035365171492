import React, { useContext, useState } from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Logo from '../../../assets/images/FullLogo.svg';
import Paper from '@material-ui/core/Paper';
import { SEND_PASSWORD_RESET } from './operations';
import SnackbarContext from '../SnackbarContext';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/client';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.sizes.fullPageNoAppBar,
    ...theme.palette.primaryBackground,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  form: {
    height: '400px',
    width: '450px',
    padding: '45px',
    boxSizing: 'border-box',
  },
}));

const SendResetLink = () => {
  const classes = useStyles();
  const { setSnack } = useContext(SnackbarContext);

  const [email, setEmail] = useState('');

  const handleChange = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  const [sendPasswordReset] = useMutation(SEND_PASSWORD_RESET, {
    variables: {
      email,
      redirectUrl: `${window.location.origin}/reset-password`,
    },
    onCompleted: (data) => {
      setSnack({
        open: true,
        message: data.userSendPasswordResetWithToken.message,
        severity: 'success',
      });
    },
    onError: () => {
      setSnack({
        open: true,
        message: 'User does not exist.',
        severity: 'error',
      });
    },
  });

  return (
    <div className={classes.root}>
      <Paper className={classes.form}>
        <img id="logo" src={Logo} alt="Welligence-Logo" width="200px" height="75px" />
        <Typography variant="h4" style={{ margin: '30px 0 15px' }}>
          Password Reset
        </Typography>
        <Grid container direction="column" justifyContent="space-around" align="center" spacing={3}>
          <Grid item xs={12} align="center">
            <TextField
              value={email}
              onChange={handleChange}
              fullWidth
              id="user_email"
              name="user[email]"
              label="Email"
              variant="filled"
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="secondary" onClick={sendPasswordReset}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default SendResetLink;
