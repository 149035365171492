import React, { useCallback, useEffect, useState } from 'react';

import Graph from '../../../Graphs/graph';
import GraphContainer from '../../../Graphs/graphContainer';
import GraphControls from '../../../Graphs/GraphControls';
import Grid from '@material-ui/core/Grid';
import Modal from '../../../Modals';
import { getAdjustedLayout } from './helpers';
import { useStyles } from './styles';

const ChartGraph = ({
  data,
  title,
  loading,
  xConfig,
  yConfig,
  stackByConfig,
  graphLayout,
  open = false,
  openModal = () => null,
  closeModal = () => null,
  isInModal = false,
}) => {
  const enableChartSettingsButton = xConfig?.options?.length > 1 || yConfig?.options?.length > 1;
  const [showLegend, setShowLegend] = useState(true);
  const [openControlsModal, setOpenControlsModal] = useState(false);
  const [layout, setLayout] = useState(graphLayout);
  const graphConfig = {
    modeBarButtonsToRemove: [
      'pan2d',
      'zoomIn2d',
      'zoomOut2d',
      'autoScale2d',
      'lasso2d',
      'select2d',
    ],
  };

  useEffect(() => {
    setLayout(getAdjustedLayout(data, graphLayout, isInModal, showLegend));
  }, [data, graphLayout, isInModal, showLegend]);

  const onRelayout = useCallback(
    (event) => {
      // use data range as default x-axis range, otherwise use the range from the event
      const xAxisRange = [
        event?.['xaxis.range[0]'] || 0,
        event?.['xaxis.range[1]'] || data[0]?.x?.length,
      ];
      setLayout(getAdjustedLayout(data, graphLayout, isInModal, showLegend, xAxisRange));
    },
    [data, graphLayout, isInModal, showLegend],
  );

  if (isInModal && showLegend) {
    // show legend for all traces when in modal
    data.forEach((trace) => (trace.showlegend = true));
  }

  return (
    <GraphContainer
      open={open}
      setOpen={openModal}
      setClose={closeModal}
      setOpenControlsModal={setOpenControlsModal}
      showToggle={!isInModal}
      title={title}
      loading={loading}
      enableChartSettingsButton={enableChartSettingsButton}
      showPlotlyLegendToggle
      showPlotlyLegend={showLegend}
      setShowPlotlyLegend={setShowLegend}
      disableDownload
    >
      <Graph
        data={data}
        config={graphConfig}
        loading={loading}
        layout={layout}
        onRelayout={onRelayout}
      />
      {enableChartSettingsButton && (
        <GraphControls
          typesLabel="Aggregate By"
          types={xConfig?.options}
          graphType={xConfig?.value || ''}
          setGraphType={xConfig?.setValue}
          compareTo={yConfig?.value}
          setCompareTo={yConfig?.setValue}
          compareToOptions={yConfig?.options}
          stackType={stackByConfig?.value}
          setStackType={stackByConfig?.setValue}
          stackTypes={stackByConfig?.options}
          open={openControlsModal}
          setOpen={setOpenControlsModal}
        />
      )}
    </GraphContainer>
  );
};

export const MaGraph = ({ data, title, xConfig, yConfig, stackByConfig, loading, graphLayout }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  return (
    <div>
      <ChartGraph
        data={data}
        title={title}
        loading={loading}
        xConfig={xConfig}
        yConfig={yConfig}
        stackByConfig={stackByConfig}
        graphLayout={graphLayout}
        openModal={openModal}
      />
      <Modal
        open={open}
        handleClose={closeModal}
        title="Chart Expansion"
        titleAlignedWithClose
        styles={classes}
      >
        <Grid container className={classes?.root} style={{ height: '100%' }}>
          <ChartGraph
            data={data}
            title={title}
            loading={loading}
            xConfig={xConfig}
            yConfig={yConfig}
            stackByConfig={stackByConfig}
            graphLayout={graphLayout}
            isInModal
            open={open}
            openModal={openModal}
            closeModal={closeModal}
          />
        </Grid>
      </Modal>
    </div>
  );
};
