import { ANALYTICS_EVENTS, ANALYTICS_EVENT_ACTIONS } from '../../utils/constants';
import { DOWNLOAD_MONTHLY_DATA, DOWNLOAD_YEARLY_DATA } from './constants';
import React, { useContext, useState } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import GetAppIcon from '@material-ui/icons/GetApp';
import LegendToggleIcon from '../Icons/LegendToggleIcon';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PageviewIcon from '@material-ui/icons/Pageview';
import Paper from '@material-ui/core/Paper';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import SnackbarContext from '../SnackbarContext';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { defaultDimensions } from '../../utils/analytics';
import { getUserId } from '../../utils/auth';
import { makeStyles } from '@material-ui/core/styles';
import { useCurrentUser } from '../CurrentUserContext';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  appbar: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    boxShadow: 'none',
    border: 'solid rgba(0, 0, 0, 0.12) thin',
    alignItems: 'center',
    paddingLeft: '6px',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    boxShadow: 'none',
    backgroundColor: 'white',
    borderLeft: 'solid rgba(0, 0, 0, 0.12) thin',
    borderRadius: '0',
  },
  accentBackground: {
    ...theme.palette.transparentBackgroundAccent,
  },
  link: {
    '&.Mui-disabled': {
      pointerEvents: 'auto',
    },
    color: 'inherit',
    textDecoration: 'none',
    fontFamily: theme.fonts.base,
    fontSize: '14px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },
  title: {
    boxShadow: 'none',
    backgroundColor: 'white',
    borderRight: 'solid rgba(0, 0, 0, 0.12) thin',
    borderRadius: '0',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    justifyContent: 'flex-start',
  },
  transparent: {
    backgroundColor: 'transparent',
    color: 'white',
  },
  transparentButton: {
    backgroundColor: 'transparent',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
    },
  },
}));

export default ({
  children,
  open,
  setClose,
  setOpen,
  setOpenControlsModal,
  setShowPlotlyLegend,
  showLineToggle,
  showPlotlyLegend,
  showPlotlyLegendToggle,
  stacked,
  setStacked,
  title,
  loading,
  downloadCallback,
  appBarId,
  monthly,
  consolidateAssetsProps,
  linkToPage,
  darkTheme,
  googleAnalyticsTag,
  disableDownload,
  disableStackedChart,
  enableChartSettingsButton = true,
}) => {
  const classes = useStyles();
  const [menuEl, setMenuEl] = useState(null);
  const { setSnack } = useContext(SnackbarContext);
  const { flashDownloadLimitMessage } = useCurrentUser();

  const handleMenuClick = (event) => {
    setMenuEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuEl(null);
  };
  const handleDownload = (dateType) => {
    gtag('event', ANALYTICS_EVENTS.event, {
      event_category: googleAnalyticsTag,
      event_action: ANALYTICS_EVENT_ACTIONS.download,
      event_label: 'production_excel',
      userId: getUserId(),
      ...defaultDimensions,
    });
    setSnack({
      open: true,
      message: 'Your download should begin shortly.',
      severity: 'success',
    });
    downloadCallback(
      (errors) => setSnack({ open: true, message: errors[0].message, severity: 'error' }),
      flashDownloadLimitMessage,
      dateType,
    );
  };
  const handleMenuItemClick = (dateType) => {
    handleDownload(dateType);
    handleMenuClose();
  };

  return (
    <Paper
      className={clsx({ [classes.root]: true, [classes.transparent]: !!darkTheme })}
      square
      elevation={1}
    >
      <AppBar
        id={appBarId}
        className={clsx({ [classes.appbar]: true, [classes.accentBackground]: !!darkTheme })}
        style={title ? { justifyContent: 'space-between' } : { justifyContent: 'flex-end' }}
        position="static"
      >
        {title && (
          <Tooltip title={title}>
            <Typography variant="body1" noWrap>
              {title}
            </Typography>
          </Tooltip>
        )}
        <div className={classes.buttonsContainer}>
          {linkToPage && (
            <Tooltip title={linkToPage.title}>
              <span>
                <Button
                  variant="contained"
                  className={clsx({
                    [classes.button]: true,
                    [classes.transparentButton]: !!darkTheme,
                  })}
                  onClick={() => {
                    gtag('event', ANALYTICS_EVENTS.event, {
                      event_category: googleAnalyticsTag,
                      event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
                      event_label: 'view_portfolio_page',
                      userId: getUserId(),
                      ...defaultDimensions,
                    });
                    if (linkToPage.callback) {
                      linkToPage.callback();
                    }
                    window.open(linkToPage.page, '_blank');
                  }}
                >
                  <PageviewIcon />
                </Button>
              </span>
            </Tooltip>
          )}
          {showPlotlyLegendToggle && (
            <Tooltip title="Toggle Graph Legend">
              <Button
                variant="contained"
                className={clsx({
                  [classes.button]: true,
                  [classes.transparentButton]: !!darkTheme,
                })}
                onClick={() => {
                  gtag('event', ANALYTICS_EVENTS.event, {
                    event_category: googleAnalyticsTag,
                    event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
                    event_label: 'toggle_plotly_legend',
                    userId: getUserId(),
                    ...defaultDimensions,
                  });
                  setShowPlotlyLegend(!showPlotlyLegend);
                }}
              >
                <LegendToggleIcon />
              </Button>
            </Tooltip>
          )}
          {showLineToggle && (
            <Tooltip title="Toggle Line and Stacked">
              <Button
                variant="contained"
                className={clsx({
                  [classes.button]: true,
                  [classes.transparentButton]: !!darkTheme,
                })}
                disabled={disableStackedChart}
                onClick={() => {
                  gtag('event', ANALYTICS_EVENTS.event, {
                    event_category: googleAnalyticsTag,
                    event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
                    event_label: 'toggle_stacked_graph',
                    userId: getUserId(),
                    ...defaultDimensions,
                  });
                  setStacked(!stacked);
                }}
              >
                <ShowChartIcon />
              </Button>
            </Tooltip>
          )}
          {open ? (
            <Tooltip title="Exit Fullscreen">
              <Button
                variant="contained"
                className={clsx({
                  [classes.button]: true,
                  [classes.transparentButton]: !!darkTheme,
                })}
                onClick={() => {
                  gtag('event', ANALYTICS_EVENTS.event, {
                    event_category: googleAnalyticsTag,
                    event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
                    event_label: 'exit_fullscreen',
                    userId: getUserId(),
                    ...defaultDimensions,
                  });
                  setClose();
                }}
              >
                <FullscreenExitIcon />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title="Fullscreen">
              <Button
                variant="contained"
                className={clsx({
                  [classes.button]: true,
                  [classes.transparentButton]: !!darkTheme,
                })}
                onClick={() => {
                  gtag('event', ANALYTICS_EVENTS.event, {
                    event_category: googleAnalyticsTag,
                    event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
                    event_label: 'enter_fullscreen',
                    userId: getUserId(),
                    ...defaultDimensions,
                  });
                  setOpen();
                }}
              >
                <FullscreenIcon />
              </Button>
            </Tooltip>
          )}
          {!disableDownload && !consolidateAssetsProps && (
            <Tooltip title="Download Data">
              <span>
                <Button
                  variant="contained"
                  className={clsx({
                    [classes.button]: true,
                    [classes.transparentButton]: !!darkTheme,
                  })}
                  disabled={loading}
                  onClick={
                    monthly || consolidateAssetsProps ? handleMenuClick : () => handleDownload()
                  }
                >
                  <GetAppIcon />
                </Button>
              </span>
            </Tooltip>
          )}
          {monthly && (
            <Menu
              anchorEl={menuEl}
              keepMounted
              open={Boolean(menuEl)}
              onClose={() => handleMenuClose()}
            >
              <MenuItem
                className={classes.link}
                onClick={() => handleMenuItemClick(DOWNLOAD_YEARLY_DATA)}
              >
                Yearly
              </MenuItem>
              <MenuItem
                className={classes.link}
                onClick={() => handleMenuItemClick(DOWNLOAD_MONTHLY_DATA)}
              >
                Monthly
              </MenuItem>
            </Menu>
          )}
          {consolidateAssetsProps && (
            <Tooltip title="Download Data">
              <span>
                <Button
                  variant="contained"
                  className={clsx({
                    [classes.button]: true,
                    [classes.transparentButton]: !!darkTheme,
                  })}
                  disabled={loading}
                  onClick={handleDownload}
                >
                  <GetAppIcon />
                </Button>
              </span>
            </Tooltip>
          )}
          {enableChartSettingsButton && (
            <Tooltip title="Chart Settings">
              <Button
                variant="contained"
                className={clsx({
                  [classes.button]: true,
                  [classes.transparentButton]: !!darkTheme,
                })}
                onClick={() => {
                  gtag('event', ANALYTICS_EVENTS.event, {
                    event_category: googleAnalyticsTag,
                    event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
                    event_label: 'chart_settings',
                    userId: getUserId(),
                    ...defaultDimensions,
                  });
                  setOpenControlsModal(true);
                }}
              >
                <MenuIcon />
              </Button>
            </Tooltip>
          )}
        </div>
      </AppBar>
      <div style={{ height: 'calc(100% - 36px)' }}>{children}</div>
    </Paper>
  );
};
