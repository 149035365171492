import {
  ANALYTICS_EVENTS,
  ANALYTICS_EVENT_ACTIONS,
  DATA_DOWNLOADS_PATH,
} from '../../../../utils/constants';
import {
  INDUSTRY_TRACKER_TYPES,
  SECURE_REGION_DOWNLOAD_QUERY,
} from '../../../Downloads/operations';
import React, { useCallback, useContext, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';

import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import Grid from '@material-ui/core/Grid';
import { INDUSTRY_TRACKER_IMAGES } from '../../constants';
import Modal from '../../../Modals';
import OilRigIcon from '../../../Icons/OilRigIcon';
import { ShinyLink } from '../../../Shiny';
import SnackbarContext from '../../../SnackbarContext';
import Typography from '@material-ui/core/Typography';
import { defaultDimensions } from '../../../../utils/analytics';
import { getUserId } from '../../../../utils/auth';
import { makeStyles } from '@material-ui/core/styles';
import { useCurrentUser } from '../../../CurrentUserContext';
import { useFeatureFlags } from '../../../../contexts/FeatureFlagsContext';

const useStyles = makeStyles((theme) => ({
  button: {
    ...theme.palette.button,
  },
  centeredButton: {
    ...theme.palette.button,
  },
  modal: {
    height: 'max-content',
    width: '70%', // override default modal width defined in Modals/index.js
    maxHeight: '90%',
  },
  modalButtons: {
    margin: '15px 0px 30px',
  },
  modalContent: {
    display: 'flex',
    paddingLeft: '80px',
    paddingRight: '80px',
    overflow: 'auto',
    margin: 'unset',
    width: 'unset',
  },
  modalGridItem: {
    minWidth: '190px',
  },
  updated: {
    ...theme.palette.accentText,
  },
}));

export default () => {
  const classes = useStyles();
  const { setSnack } = useContext(SnackbarContext);
  const { isUserEntitled } = useCurrentUser();
  const { inDevelopment } = useFeatureFlags();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { data: industryTrackerDownloadTypesData } = useQuery(INDUSTRY_TRACKER_TYPES, {
    fetchPolicy: 'cache-and-network',
  });
  const industryTrackerDownloadTypes =
    industryTrackerDownloadTypesData?.industryTrackerDownloadTypes;

  const downloadQueryOptions = useCallback(
    (dataKey) => ({
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const { url, downloadMessage } = data[dataKey];
        if (url) {
          setSnack({
            open: true,
            message: `Your download should begin shortly.${downloadMessage}`,
            severity: 'success',
          });
          window.location.href = url;
        }
      },
      onError: (error) => {
        setSnack({
          severity: 'error',
          message: error.message,
          open: true,
        });
      },
    }),
    [setSnack],
  );

  const [getSecureRegionDownload] = useLazyQuery(
    SECURE_REGION_DOWNLOAD_QUERY,
    downloadQueryOptions('secureRegionDownload'),
  );

  const getDownloadLink = (downloadType, region, subType) => {
    getSecureRegionDownload({
      variables: { downloadType, region, subType },
    });
  };

  return (
    <Grid container item spacing={2}>
      <Grid item xs={2} />
      <Grid container item spacing={2} xs={8}>
        <Grid item>
          <ShinyLink
            startAdornment={<GetAppIcon fontSize="small" />}
            link={{ title: 'view downloads', page: 'download' }}
            styles={{ link: classes.button }}
            disabled={!isUserEntitled(DATA_DOWNLOADS_PATH)}
            variant="contained"
          />
        </Grid>
        <Grid item>
          <Button onClick={handleOpen} variant="contained" className={classes.button}>
            <OilRigIcon fontSize="small" />
            industry trackers
          </Button>
          <Modal
            title="Access the most up-to-date industry trackers."
            open={open}
            handleClose={handleClose}
            styles={classes}
          >
            <Grid
              container
              spacing={5}
              justifyContent="space-between"
              className={classes.modalContent}
            >
              {industryTrackerDownloadTypes?.map(({ name, type, regions }) => {
                const image = INDUSTRY_TRACKER_IMAGES(inDevelopment)[type];

                // Don't show trackers that don't have images (LNG FACILITIES):
                return (
                  image && (
                    <Grid key={type} className={classes.modalGridItem} item xs={2} align="center">
                      <img src={image} width="100px" height="65px" />
                      <Typography variant="h6" style={{ margin: '15px 0' }}>
                        {name}
                      </Typography>
                      <Grid
                        container
                        spacing={1}
                        justifyContent="center"
                        className={classes.modalButtons}
                      >
                        {regions.map(({ displayName, region, type: subType }) => (
                          <Grid key={`data-downloads-${type}-${displayName}`} item>
                            <Button
                              key={type + displayName}
                              variant="contained"
                              className={classes.centeredButton}
                              onClick={() => {
                                gtag('event', ANALYTICS_EVENTS.event, {
                                  event_category: 'IndustryTrackers',
                                  event_action: ANALYTICS_EVENT_ACTIONS.download,
                                  event_label: `${name} | ${displayName}`,
                                  userId: getUserId(),
                                  ...defaultDimensions,
                                });
                                getDownloadLink(type, region, subType);
                              }}
                            >
                              {displayName || 'Download Data'}
                            </Button>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  )
                );
              })}
            </Grid>
          </Modal>
        </Grid>
      </Grid>
    </Grid>
  );
};
