import { ANALYTICS_EVENTS, ANALYTICS_EVENT_ACTIONS } from '../../../utils/constants';
import React, { useContext } from 'react';

import Button from '@material-ui/core/Button';
import ErrorBoundary from '../../ErrorBoundary';
import GetAppIcon from '@material-ui/icons/GetApp';
import { PIPELINE_NETWORK_GOOGLE_ANALYTICS_TAG } from '../constants';
import SnackbarContext from '../../SnackbarContext';
import Tooltip from '@material-ui/core/Tooltip';
import { defaultDimensions } from '../../../utils/analytics';
import { downloadXlsx } from '../../Downloads/helpers';
import { getUserId } from '../../../utils/auth';
import { makeStyles } from '@material-ui/core/styles';
import { useCurrentUser } from '../../CurrentUserContext';

const useStyles = makeStyles((theme) => ({
  button: {
    ...theme.palette.button,
    width: '100%',
    margin: theme.spacing(1),
    justifyContent: 'space-evenly',
  },
  buttonContainer: {
    ...theme.palette.mapInfoSection,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  label: {
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'center',
  },
  orangeIcon: {
    ...theme.palette.accentText,
  },
}));

export default ({ pipelineNetwork }) => {
  const classes = useStyles();
  const { setSnack } = useContext(SnackbarContext);
  const { flashDownloadLimitMessage } = useCurrentUser();

  const downloadCallback = (errorHandler, successHandler) => {
    const path = 'pipeline-network/export-throughput/';
    const body = {
      pipelineNetworkId: pipelineNetwork.id,
    };
    const downloadName = `Welligence - Pipeline Network Throughput - ${pipelineNetwork.name}.xlsx`;

    downloadXlsx(path, body, downloadName, errorHandler, successHandler);
  };

  const handleDownload = () => {
    // gtag
    gtag('event', ANALYTICS_EVENTS.event, {
      event_category: PIPELINE_NETWORK_GOOGLE_ANALYTICS_TAG,
      event_action: ANALYTICS_EVENT_ACTIONS.download,
      event_label: 'pipeline_network_throughput_by_asset_excel',
      userId: getUserId(),
      ...defaultDimensions,
    });
    // setSnack
    setSnack({
      open: true,
      message: 'Your download should begin shortly.',
      severity: 'success',
    });
    // downloadCallback
    downloadCallback(
      (errors) => setSnack({ open: true, message: errors[0].message, severity: 'error' }),
      flashDownloadLimitMessage,
    );
  };

  return (
    <ErrorBoundary>
      <div className={classes.buttonContainer}>
        {pipelineNetwork ? (
          <Tooltip
            title="Pipeline Network Throughput By Asset"
            aria-label="pipeline network throughput by asset"
          >
            <Button className={classes.button} onClick={() => handleDownload()} variant="contained">
              <GetAppIcon fontSize="small" color="primary" className={classes.orangeIcon} />
              <div className={classes.label}>
                Pipeline Network Throughput
                <br />
                By Asset
              </div>
            </Button>
          </Tooltip>
        ) : null}
      </div>
    </ErrorBoundary>
  );
};
