import React from 'react';
import { Tooltip } from '@material-ui/core';

const hoverStyles = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const TABLE_PAGE_SIZE_OPTIONS = [10, 25, 50, 100];

export const TABLE_COLUMNS = [
  {
    field: 'countryName',
    headerName: 'Country',
    width: 200,
    filterable: false,
    valueGetter: (params) => params.row?.country?.displayName,
  },
  { field: 'assetName', headerName: 'Asset Name', width: 200, filterable: false },
  { field: 'shoreStatus', headerName: 'Shore Status', width: 200, filterable: false },
  {
    field: 'dealType',
    headerName: 'Deal Type',
    width: 200,
    filterable: false,
    valueGetter: (params) => params.row?.maCompanyTransaction?.maDeal?.dealType,
  },
  {
    field: 'effectiveDate',
    headerName: 'Effective Date',
    type: 'date',
    width: 200,
    filterable: false,
    valueGetter: (params) => params.row?.maCompanyTransaction?.maDeal?.effectiveDate,
    renderCell: ({ value }) => (
      <span>
        {new Date(value).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          timeZone: 'UTC',
        })}
      </span>
    ),
  },
  {
    field: 'buyerCompanyName',
    headerName: 'Buyer',
    width: 200,
    filterable: false,
    valueGetter: (params) => params.row?.maCompanyTransaction?.buyerCompany?.name,
  },
  {
    field: 'sellerCompanyName',
    headerName: 'Seller',
    width: 200,
    filterable: false,
    valueGetter: (params) => params.row?.maCompanyTransaction?.sellerCompany?.name,
  },
  {
    field: 'comment',
    headerName: 'Comment',
    width: 400,
    filterable: false,
    valueGetter: (params) => params.row?.maCompanyTransaction?.maDeal?.comment,
    renderCell: (params) => (
      <Tooltip title={params.value}>
        <span style={hoverStyles}>{params.value}</span>
      </Tooltip>
    ),
  },
  {
    field: 'regionName',
    headerName: 'Region',
    width: 200,
    filterable: false,
    valueGetter: (params) => params.row?.country?.region?.displayName,
  },
  {
    field: 'workingInterest',
    headerName: 'Stake Transacted (%)',
    width: 200,
    filterable: false,
  },
  {
    field: 'weaWorkingInterestValuation',
    headerName: 'Transacted Valuation (US$MM)',
    width: 200,
    filterable: false,
    valueFormatter: (params) => params.value?.toFixed(2),
  },
  {
    field: 'weaReservesAcquisitionCost',
    headerName: 'Reserves Acquisition Cost ($/boe)',
    width: 200,
    filterable: false,
    valueFormatter: (params) => params.value?.toFixed(2),
  },
  {
    field: 'weaPriceFlowingBarrel',
    headerName: 'Price per Flowing Barrel ($/boe/d)',
    width: 200,
    filterable: false,
    valueFormatter: (params) => params.value?.toFixed(0),
  },
  {
    field: 'weaWorkingInterestReserves',
    headerName: 'Transacted Reserves (MMboe)',
    width: 200,
    filterable: false,
    valueFormatter: (params) => params.value?.toFixed(2),
  },
  {
    field: 'weaWorkingInterestLiquidsReserves',
    headerName: 'Transacted Oil/Condensate Reserves (MMbbl)',
    width: 200,
    filterable: false,
    valueFormatter: (params) => params.value?.toFixed(2),
  },
  {
    field: 'weaWorkingInterestGasReserves',
    headerName: 'Transacted Gas Reserves (Bcf)',
    width: 200,
    filterable: false,
    valueFormatter: (params) => params.value?.toFixed(2),
  },
  {
    field: 'weaWorkingInterestProduction',
    headerName: 'Transacted Production (boe/d)',
    width: 200,
    filterable: false,
    valueFormatter: (params) => params.value?.toFixed(0),
  },
  {
    field: 'weaWorkingInterestLiquidsProduction',
    headerName: 'Transacted Oil/Condensate Production (bbl/d)',
    width: 200,
    filterable: false,
    valueFormatter: (params) => params.value?.toFixed(0),
  },
  {
    field: 'weaWorkingInterestGasProduction',
    headerName: 'Transacted Gas Production (MMcf/d)',
    width: 200,
    filterable: false,
    valueFormatter: (params) => params.value?.toFixed(2),
  },
  {
    field: 'weaRemainingEmissionsIntensity',
    headerName: 'Remaining Emissions Intensity (kgCO2e/boe)',
    width: 200,
    filterable: false,
    valueFormatter: (params) => params.value?.toFixed(2),
  },
  {
    field: 'weaCurrentEmissionsIntensity',
    headerName: 'Current Emissions Intensity (kgCO2e/boe)',
    width: 200,
    filterable: false,
    valueFormatter: (params) => params.value?.toFixed(2),
  },
];
