import { AppBar as AppBarMui, Button, Toolbar } from '@material-ui/core';
import {
  DATA_DOWNLOADS_PATH,
  RESERVES_DEFINITION_PATH,
  ROOT_PATH,
  SETTINGS_PATH,
} from '../../utils/constants';
import { Link, useLocation } from 'react-router-dom';
import React, { useContext, useState } from 'react';

import CloseIcon from '@material-ui/icons/Close';
import { DropDown } from './DropDown';
import FullLogo from '../../../assets/images/FullLogo.svg';
import { GlobalSearch } from '../GlobalSearch';
import MenuIcon from '@material-ui/icons/Menu';
import { MobileTray } from './MobileTray';
import { ShinyLink } from '../Shiny';
import { SidePageButton } from '../SidePageButton';
import { SignOutButton } from './SignOutButton';
import WLogo from '../../../assets/images/WLogo.svg';
import WalkthroughContext from '../WalkthroughContext';
import { useAppBarStyles } from './AppBar.style';
import { useCurrentUser } from '../CurrentUserContext';
import { useLinks } from './hooks/useLinks';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const getUserInitials = (user, userLoading) => {
  if (userLoading) return '';
  if (!user || !user.firstName || !user.lastName) return 'W';
  return user.firstName[0] + user.lastName[0];
};

export const AppBar = ({ forceNewTab }) => {
  const classes = useAppBarStyles();
  const { hasWalkthrough, setWalkthrough, setWalkthroughSession } = useContext(WalkthroughContext);
  const { currentUser, userLoading, isUserEntitled } = useCurrentUser();
  const isLgMediaQueryPlus = useMediaQuery('(min-width:1490px)');
  const isMobile = useMediaQuery('(max-width:750px)');
  const [openMobileTray, setOpenMobileTray] = useState(false);
  const [sideButtonVisible, setSideButtonVisible] = useState(true);
  const location = useLocation();
  const links = useLinks();
  const path = location.pathname;

  const { available: walkthroughEnabled, sideButtonShown: walkthroughShowSideButton } =
    hasWalkthrough();

  let Logo, logoWidth, logoHeight;
  if (isLgMediaQueryPlus) {
    Logo = FullLogo;
    logoHeight = '45px';
    logoWidth = '172px';
  } else {
    Logo = WLogo;
    logoHeight = '45px';
    logoWidth = '50px';
  }

  return (
    <div>
      <AppBarMui position="static" className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.left}>
            <div>
              <Link to={ROOT_PATH}>
                <img
                  id="logo"
                  src={Logo}
                  alt="Welligence-Logo"
                  width={logoWidth}
                  height={logoHeight}
                />
              </Link>
            </div>
            {!isMobile &&
              links?.map((link, i) =>
                link.type === 'menu' ? (
                  <DropDown key={`${i}-${link.title}`} link={link} forceNewTab={forceNewTab} />
                ) : link.type === 'shiny' ? (
                  <ShinyLink
                    key={`${i}-${link.title}`}
                    link={link}
                    styles={{ link: classes.link }}
                    disabled={!isUserEntitled(DATA_DOWNLOADS_PATH)}
                  />
                ) : link.type === 'href' ? (
                  <Button
                    key={`${i}-${link.title}`}
                    color="inherit"
                    underline="none"
                    className={classes.link}
                    onClick={() => window.open(link.link, '_blank')}
                  >
                    {link.title}
                  </Button>
                ) : (
                  <Button
                    key={`${i}-${link.title}`}
                    component={Link}
                    to={`${link.link}`}
                    color="inherit"
                    underline="none"
                    disabled={!isUserEntitled(link.link)}
                    className={path.indexOf(link.link) !== -1 ? classes.selectedLink : classes.link}
                  >
                    {link.title}
                  </Button>
                ),
              )}
          </div>
          {(!openMobileTray || !isMobile) && <GlobalSearch />}
          {isMobile ? (
            <Button
              onClick={() => {
                setOpenMobileTray(!openMobileTray);
              }}
              color="inherit"
              underline="none"
              className={classes.link}
            >
              {openMobileTray ? <CloseIcon /> : <MenuIcon />}
            </Button>
          ) : (
            <div id="appbar-menu-button" className={classes.right}>
              <DropDown
                link={{
                  title: getUserInitials(currentUser, userLoading),
                  menuItems: [
                    {
                      title: 'Settings',
                      link: SETTINGS_PATH,
                    },
                    {
                      title: 'Help',
                      link: RESERVES_DEFINITION_PATH,
                    },
                    ...(walkthroughEnabled
                      ? [
                          {
                            type: 'jsx',
                            jsx: (
                              <Button
                                key="page-tutorial"
                                color="inherit"
                                underline="none"
                                className={classes.link}
                                style={{ width: '100%' }}
                                onClick={() => {
                                  setWalkthrough(true);
                                  setSideButtonVisible(false);
                                }}
                              >
                                Page tutorial
                              </Button>
                            ),
                          },
                        ]
                      : []),
                    { type: 'jsx', jsx: <SignOutButton key="sign-out" /> },
                  ],
                }}
                isUserMenu
              />
            </div>
          )}
        </Toolbar>
      </AppBarMui>
      {openMobileTray && isMobile && (
        <MobileTray
          forceNewTab={forceNewTab}
          onButtonClick={() => setOpenMobileTray(false)}
          onCloseTray={() => {
            setOpenMobileTray(!openMobileTray);
          }}
        />
      )}
      {!!walkthroughEnabled && walkthroughShowSideButton && sideButtonVisible && (
        <SidePageButton
          onClick={() => setWalkthrough(true)}
          label="START PAGE TUTORIAL"
          isOpen
          closeAble
          className={classes.walkthrough}
          onClose={() => {
            setWalkthroughSession(false);
            setSideButtonVisible(false);
          }}
          boxy
          left
        />
      )}
    </div>
  );
};
