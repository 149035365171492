// Defaults
const DEFAULT_GAS_COLOR = 'rgba(170, 24, 24, .8)';
const DEFAULT_OIL_COLOR = 'rgba(79, 170, 18, .8)';
const DEFAULT_HIGHLIGHT_COLOR = '#00FFFF';
const BLACK_COLOR = '#000000';
const WHITE_COLOR = '#FFFFFF';

// Transparent color
export const TRANSPARENT_COLOR = 'rgba(0, 0, 0, 0)';

// Color for clickable country boundaries
export const DEFAULT_BOUNDARY_COLOR = BLACK_COLOR;
export const BOUNDARY_COLOR = DEFAULT_HIGHLIGHT_COLOR;

// Screening (assets, blocks, leases)
export const SCREENING_BOUNDARY_DEFAULT_COLOR = DEFAULT_HIGHLIGHT_COLOR;
export const SCREENING_BOUNDARY_HIGHLIGHT_COLOR = '#0900FF';
export const SCREENING_CLUSTER_COLOR = DEFAULT_HIGHLIGHT_COLOR;

// Zero value color
export const ZERO_VALUE_FILL = WHITE_COLOR;

// Red color palette
export const RED_MIN = '#FDD0A2';
export const RED_LOW = '#FD9244';
export const RED_MEDIUM = '#D24B0A';
export const RED_HIGH = '#A63603';
export const RED_MAX = '#591502';

// Tan color palette
export const TAN_MIN = '#FDFDDA';
export const TAN_LOW = '#FDF1BB';
export const TAN_MEDIUM = '#FDE39B';
export const TAN_HIGH = '#FDC879';
export const TAN_MAX = '#FCAD6B';

// Green color palette
export const GREEN_MIN = '#F7FCB9';
export const GREEN_LOW = '#ADDD8E';
export const GREEN_MEDIUM = '#41AB5D';
export const GREEN_HIGH = '#006837';
export const GREEN_MAX = '#023827';

// Map ruler colors
export const MAP_RULER_COLORS = {
  monochrome: '#1E8553',
  satellite: '#FF7F50',
};

// Label colors (block = asset/block/lease)
export const DEFAULT_LABEL_COLOR = WHITE_COLOR;
export const BLOCK_LABEL_COLOR = '#E6D800';
export const FIELD_LABEL_COLOR = '#747AD2';

/* ENTITY COLORS */
// Asset/block/lease color
export const BLOCK_COLOR = 'rgba(189, 189, 189, .45)';
export const UNLICENSED_BLOCK_COLOR = 'rgba(123, 123, 123, .45)';

// Field colors
export const FIELD_COLORS = {
  gas: DEFAULT_GAS_COLOR,
  oil: DEFAULT_OIL_COLOR,
  default: DEFAULT_GAS_COLOR,
};

// Formation colors
export const FORMATION_COLORS = {
  blackOil: 'rgba(48, 138, 60, .3)',
  volatileOil: 'rgba(58, 199, 77, .3)',
  gasAndCondensate: 'rgba(237, 237, 14, .3)',
  wetGas: 'rgba(222, 151, 42, .3)',
  dryGas: 'rgba(232, 0, 0, .8)',
  default: 'rgba(189, 189, 189, .8)',
};

// Industry Type colors (Used for CCUS facilities and projects)
export const INDUSTRY_TYPE_COLORS = {
  boundary: 'rgba(0, 0, 0, .8)',
  agriculture: '#aaffc3',
  ammonia: '#f032e6',
  automobile: '#ffe119',
  cement: '#a9a9a9',
  chemicals: '#808000',
  default: BLACK_COLOR,
  directAirCapture: '#fabed4',
  ethanol: '#42d4f4',
  foodAndBeverage: '#ffd8b1',
  hydrogen: '#911eb4',
  ironAndSteel: '#dcbeff',
  mining: '#000075',
  naturalGasProcessing: '#e6194b',
  naturalGasTransmissionCompression: '#f58231',
  oilAndGasExtraction: '#469990',
  other: '#fffac8',
  powerPlant: '#4363d8',
  pulpAndPaper: WHITE_COLOR,
  refineries: '#800000',
  waste: '#9a6324',
};

// Pipeline colors
export const PIPELINE_COLORS = {
  gas: DEFAULT_GAS_COLOR,
  oil: DEFAULT_OIL_COLOR,
  default: 'rgba(255, 255, 0, .8)',
};

// Presalt polygon color
export const PRESALT_POLYGON_COLOR = 'rgba(135, 206, 235, .35)';

// Well colors
export const WELL_COLORS = {
  boundary: 'rgba(0, 0, 0, .8)',
  default: 'rgba(255, 98, 80, .7)',
  exploration_and_appraisal: 'rgba(125, 41, 255, .8)',
  exploration: 'rgba(125, 41, 255, .8)',
  wells_to_watch: 'rgba(255, 255, 0, .8)',
  injection: 'rgba(3, 65, 252, .8)',
  production: 'rgba(252, 152, 3, .8)',
};

export const WELL_LABEL_COLORS = {
  default: 'rgba(255, 98, 80, 1)',
  appraisal: 'rgba(185, 140, 255, 1)',
  exploration_and_appraisal: 'rgba(185, 140, 255, 1)',
  wells_to_watch: 'rgba(255, 255, 0, 1)',
  injection: 'rgba(86, 131, 233, 1)',
  production: 'rgba(255, 181, 72, 1)',
};
