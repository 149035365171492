import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Legend from './legend';
import TabbedLegend from './tabbedLegend';

const useStyles = makeStyles((theme) => ({
  hiddenContainer: { ...theme.palette.hiddenContainer },
  legendContainer: {
    position: 'absolute',
    right: '140px',
    bottom: '10px',
    maxWidth: '75%',
  },
}));

export default (props) => {
  const classes = useStyles();
  return (
    <div
      ref={props.containerRef}
      className={clsx({
        [classes.legendContainer]: true,
        [classes.hiddenContainer]: !props.enabled,
        [props.styles.legendContainer]: !!props.styles.legendContainer,
      })}
    >
      {props.tabbed ? <TabbedLegend {...props} /> : <Legend {...props} />}
    </div>
  );
};
