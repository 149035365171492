import React, { useCallback, useContext, useEffect, useState } from 'react';
import { TABLE_COLUMNS, TABLE_PAGE_SIZE_OPTIONS } from './types';

import { Button } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import SnackbarContext from '../../../SnackbarContext';
import { useDownloadMaTransactions } from './hooks/useDownloadMaTransactions';
import { useGetMaTransactions } from './hooks/useGetMaTransactions';
import { useStyles } from './styles';

const SESSION_STORAGE_KEY = 'MaTransactionTable';
const getInitialTableSettings = () => {
  const sessionSettings = sessionStorage.getItem(SESSION_STORAGE_KEY);
  if (sessionSettings) {
    const { page, pageSize, sortModel, columns } = JSON.parse(sessionSettings);
    return {
      page,
      pageSize,
      sortModel,
      // Merge the default columns with the saved columns to keep the valueGetter functions
      columns: TABLE_COLUMNS.map((col) => ({
        ...col,
        ...columns.find((c) => c.field === col.field),
      })),
    };
  }

  return {
    page: 0,
    pageSize: TABLE_PAGE_SIZE_OPTIONS[1],
    sortModel: [{ field: 'effectiveDate', sort: 'desc' }],
    columns: TABLE_COLUMNS,
  };
};

export const TransactionTable = () => {
  const classes = useStyles();

  const { setSnack } = useContext(SnackbarContext);
  const [downloading, setDownloading] = useState(false);

  const {
    page: defaultPage,
    pageSize: defaultPageSize,
    sortModel: defaultSortModel,
    columns: defaultColumns,
  } = getInitialTableSettings();
  const [page, setPage] = useState(defaultPage);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [columns, setColumns] = useState(defaultColumns);
  const [sortModel, setSortModel] = useState(defaultSortModel);

  const { downloadMaTransactions } = useDownloadMaTransactions();
  const { data, totalCount, loading, error } = useGetMaTransactions(
    page,
    pageSize,
    sortModel[0]?.sort,
    sortModel[0]?.field,
  );

  useEffect(() => {
    // Save the current state to sessionStorage
    sessionStorage.setItem(
      SESSION_STORAGE_KEY,
      JSON.stringify({ page, pageSize, sortModel, columns }),
    );
  }, [page, pageSize, sortModel, columns]);

  const onColumnVisibilityChange = useCallback(
    (colChanged) => {
      setColumns((prevColumns) => {
        const newColumns = prevColumns.map((column) =>
          column.field === colChanged.field ? { ...column, hide: !colChanged.isVisible } : column,
        );
        return newColumns;
      });
    },
    [columns, setColumns],
  );

  const onDownload = useCallback(async () => {
    setDownloading(true);
    setSnack({
      open: true,
      message: 'Your download should begin shortly.',
      severity: 'success',
    });

    const { ok, errors } = await downloadMaTransactions();

    if (!ok) {
      setSnack({
        open: true,
        message: errors[0]?.message || "We're sorry, but something went wrong.",
        severity: 'error',
      });
    }
    setDownloading(false);
  }, [downloadMaTransactions, setDownloading, setSnack]);

  if (error) return null;

  return (
    <>
      <Button
        className={classes.download}
        variant="contained"
        onClick={onDownload}
        disabled={downloading}
      >
        Download Data
      </Button>
      <DataGrid
        className={classes.table}
        rows={data}
        rowCount={totalCount}
        columns={columns}
        loading={loading}
        // TODO: once MUI is updated to v7, we need the use the new pagination model props
        pagination
        paginationMode="server"
        page={page}
        pageSize={pageSize}
        onPageChange={(page) => setPage(page)}
        onPageSizeChange={(pageSize) => setPageSize(pageSize)}
        rowsPerPageOptions={TABLE_PAGE_SIZE_OPTIONS}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={(sortModel) => setSortModel(sortModel)}
        onColumnVisibilityChange={onColumnVisibilityChange}
      />
    </>
  );
};
